const portfolioGallery = async function( $block ) {
	const { Swiper } = await import( "swiper" );
	await import( "swiper/css/bundle" );
	const { Navigation, Pagination } = await import( "swiper/modules" );
	const $slider = $block.find( ".portfolio-slider" );
	const $sliderModal = $block.find( ".portfolio-modal" );

	if ( !$slider.length ) { return false; }

	const photoGallerySwiper = new Swiper( $slider[0], {
		modules: [Navigation],
		slidesPerView: 1,
		spaceBetween: 0,
		loop: true,
		navigation: {
			prevEl: ".portfolio__slider--prev", 
			nextEl: ".portfolio__slider--next",
		},
		breakpoints: {
			1023: {spaceBetween: 89, slidesPerView: 3},
			767: {spaceBetween: 69,slidesPerView: 3},
			480: {spaceBetween: 0,slidesPerView: 1},
		}
	} );

	if ( $sliderModal.length ) {
		const photoGalleryModal = new Swiper( $sliderModal[0], {
			modules: [Navigation, Pagination],
			slidesPerView: 1,
			loop: true,
			navigation: {
				prevEl: ".portfolio__modal--prev", 
				nextEl: ".portfolio__modal--next",
			},
			pagination: {
				el: ".portfolio__modal--pagination",
				type: "bullets",
				clickable: true,
			},
		} );

		photoGalleryModal.init();

		$slider.find( ".swiper-slide" ).on( "click", function() {
			const clickedIndex = $( this ).attr( "data-swiper-slide-index" );
			photoGalleryModal.slideTo( clickedIndex );
		} );
	
		photoGalleryModal.on( "slideChange", function() {
			const activeSlide = photoGalleryModal.slides[photoGalleryModal.activeIndex];
			const activeSlideTitle = activeSlide.dataset.title;
		
			$( ".portfolio-slider--title-js" ).text( activeSlideTitle );
		} );
	}
	
	photoGallerySwiper.init();

};
 
themeUtils.loadBlock( portfolioGallery, "portfolio", ".portfolio-container" );